import React from "react";
import ApiService from "../../services/classes/Api";
import Validator from "../../helpers/Validations";
import Loading from "./Loading";
import {Col, FormGroup, Row} from "reactstrap";
import {
	Checkbox,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	IconButton,
	Button
} from "@material-ui/core";
import { AddAPhoto } from "@material-ui/icons";
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor,  } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Base64ImageDropDialog from "../Dialogs/Base64ImageDropDialog";
import AddRecordDialog from "./AddRecordDialog";
import { ControlledEditor } from "@monaco-editor/react";

const codeEditorOptions = {
	"acceptSuggestionOnCommitCharacter": true,
	"acceptSuggestionOnEnter": "on",
	"accessibilitySupport": "auto",
	"autoIndent": true,
	"automaticLayout": true,
	"codeLens": true,
	"colorDecorators": true,
	"contextmenu": true,
	"cursorBlinking": "blink",
	"cursorSmoothCaretAnimation": false,
	"cursorStyle": "line",
	"disableLayerHinting": false,
	"disableMonospaceOptimizations": false,
	"dragAndDrop": false,
	"fixedOverflowWidgets": false,
	"folding": true,
	"foldingStrategy": "auto",
	"fontLigatures": false,
	"formatOnPaste": false,
	"formatOnType": false,
	"hideCursorInOverviewRuler": false,
	"highlightActiveIndentGuide": true,
	"links": true,
	"mouseWheelZoom": false,
	"multiCursorMergeOverlapping": true,
	"multiCursorModifier": "alt",
	"overviewRulerBorder": true,
	"overviewRulerLanes": 2,
	"quickSuggestions": true,
	"quickSuggestionsDelay": 100,
	"readOnly": false,
	"renderControlCharacters": false,
	"renderFinalNewline": true,
	"renderIndentGuides": true,
	"renderLineHighlight": "all",
	"renderWhitespace": "none",
	"revealHorizontalRightPadding": 30,
	"roundedSelection": true,
	"rulers": [],
	"scrollBeyondLastColumn": 0,
	"scrollBeyondLastLine": false,
	"selectOnLineNumbers": true,
	"selectionClipboard": true,
	"selectionHighlight": true,
	"showFoldingControls": "mouseover",
	"smoothScrolling": false,
	"suggestOnTriggerCharacters": true,
	"wordBasedSuggestions": true,
	"wordSeparators": "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
	"wordWrap": "off",
	"wordWrapBreakAfterCharacters": "\t})]?|&,;",
	"wordWrapBreakBeforeCharacters": "{([+",
	"wordWrapBreakObtrusiveCharacters": ".",
	"wordWrapColumn": 80,
	"wordWrapMinified": true,
	"wrappingIndent": "none"
};

export default class RecordDataForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data: props.data,
			referenceableRecords: {},
			referenceableTopologies: {},
			wysiwygEditors: {},
			allLoaded: false
		}
	}

	componentDidMount() {
		this.loadData();
	}

	async loadData() {
		let { topology, topologies } = this.props;
		let { wysiwygEditors, data } = this.state;
		const fields = Object.keys(topology.shape).map(key => ({...topology.shape[key], key: key}));
		for (let f of fields) {
			if ((["topology", "auto_topology", "auto_topology_list"].includes(f.type)) && f.references) {
				const referenced = topologies.filter(t => t._id === f.references);
				if (referenced) {
					await this.loadReferenceableRecord(f.key, referenced[0], f.type);
				}
			}

			if(["wysiwyg"].includes(f.type)) {
				wysiwygEditors[f.key] = {
					contentState: data[f.key] ? ContentState.createFromBlockArray(htmlToDraft(data[f.key]).contentBlocks) : "",
					editorState: data[f.key] ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(data[f.key]).contentBlocks)) : EditorState.createEmpty()
				}
			}
		}
		this.setState({ allLoaded: true });
	}

	async loadReferenceableRecord(field, referenced, fieldType) {
		console.log("loadReferenceableRecord", field, referenced)
		return new Promise((resolve, reject) => {
			ApiService.graph(`
			{
				records(type: "${referenced.name}", dataParam: "${ fieldType === "auto_topology_list" ? "title" : "title" }") {
					_id
     			type
     			state
     			meta
     			data
     			createdAt
     			updatedAt
				}
				
				topology(name: "${referenced.name}") {
					_id
					name
					label
					plural_label
					shape
					states
				}
			}
			`).then(response => {
				if(response.status === 200) {
					console.log("REFERENCED CALLED", response.data)
					let { referenceableRecords, referenceableTopologies } = this.state;
					referenceableRecords[field] = response.data.data.records;

					referenceableTopologies[field] = response.data.data.topology;

					this.setState({ referenceableRecords, referenceableTopologies });
					resolve();
				}
			})
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevState !== this.state) {
			this.props.updateRecordData(this.state.data);
		}
	}

	setFieldValue(field, value) {
		let { data } = this.state;
		data[field] = value;
		this.setState({ data })
	}

	addItemToFieldValueList(field, key, value) {
		let { data } = this.state;
		console.log(field, key, value);
		if(Array.isArray(data[field])) {
			if(!data[field].includes(key) && value === true) {
				console.log("PUSHING")
				data[field].push(key);
			} else if(data[field].includes(key) && value === false) {
				console.log("REMOVING");
				delete data[field].splice([data[field].indexOf(key)]);
			}
		} else {
			console.log("Not an array")
			if(value === true) {
				data[field] = [key];
			}
		}
		console.log(data);
		this.setState({ data })
	}

	isFieldDisabled(field) {
		const { topology, state } = this.props;
		return topology.states[state].disableAll;
	}

	validateFieldValue(field, value) {
		const { topology, state } = this.props;
		const result = Validator.validate(this.state.data, topology, state);
		return result.filter(r => r.field === field).length > 0 ? result.filter(r => r.field === field)[0].message : false;
	}

	render() {

		if(!this.state.allLoaded)
			return <Loading/>;

		const { data, referenceableRecords, referenceableTopologies } = this.state;
		const { topology: { shape } } = this.props;
		const fields = Object.keys(shape).map(key => ({...shape[key], key}));

		return (
			<Row>
				{fields.map(f => (

					<React.Fragment>

						{
							f.type === "input" ?
								<Col md={6} sm={12}>
									<FormGroup>
										<TextField label={f.label} helperText={this.validateFieldValue(f.key, data[f.key])} disabled={this.isFieldDisabled(f)} value={data[f.key]} onChange={(v) => this.setFieldValue(f.key, v.target.value)} error={this.validateFieldValue(f.key, data[f.key])} fullWidth />
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "textarea" ?
								<Col md={12} sm={12}>
									<FormGroup>
										<TextField label={f.label} helperText={this.validateFieldValue(f.key, data[f.key])} disabled={this.isFieldDisabled(f)} value={data[f.key]} onChange={(v) => this.setFieldValue(f.key, v.target.value)} error={this.validateFieldValue(f.key, data[f.key])} fullWidth multiline />
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "checkbox" ?
								<Col md={6} sm={12}>
									<FormControlLabel
										control={
											<Checkbox checked={[true, "s", "y", "on", "1", 1].includes(data[f.key])} disabled={this.isFieldDisabled(f)} helperText={this.validateFieldValue(f.key, data[f.key])} onChange={(v) => this.setFieldValue(f.key, v.target.checked)} value={data[f.key]} />
										}
										label={f.label}
										error={this.validateFieldValue(f.key, data[f.key])}
									/>
									<FormHelperText>{this.validateFieldValue(f.key, data[f.key])}</FormHelperText>
								</Col>
								:
								null
						}

						{
							f.type === "image" ?
								<Col md={12} sm={12}>
									<InputLabel>{f.label}</InputLabel>
									<div style={{position: "relative", width: "100%", height: "250px", backgroundImage: `url(${data[f.key] || ""})`, backgroundSize: "cover", backgroundPosition: "center center", display: "flex", justifyContent: "center"}}>
										<span style={{width: "100%", height: "100%", position: "absolute", top: 0, left: 0, background: "rgba(0,0,0,0.5)"}}></span>
										<Base64ImageDropDialog
											disabled={this.isFieldDisabled(f)}
											button={
												<IconButton style={{width: "100%", color: "#fff"}} color="primary" aria-label="Aggiungi immagine">
													<AddAPhoto />
												</IconButton>
											}
											confirmCallback={(data) => this.setFieldValue(f.key, data.base64)}
										/>
									</div>
									<FormHelperText>{this.validateFieldValue(f.key, data[f.key])}</FormHelperText>
								</Col>
								:
								null
						}

						{
							f.type === "wysiwyg" ?
								<Col md={12} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<Editor
											editorState={this.state.wysiwygEditors[f.key].editorState}
											toolbarClassName="toolbarClassName"
											wrapperClassName="wrapperClassName"
											editorClassName="editorClassName"
											onEditorStateChange={(editorState) => {
												let { wysiwygEditors } = this.state;
												wysiwygEditors[f.key].editorState = editorState;
												this.setState({ wysiwygEditors });
											}}
											onContentStateChange={(content) => this.setFieldValue(f.key, draftToHtml(content))}
										/>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "js_code" ?
								<Col key={f.key} md={12} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<ControlledEditor
											height="600px"
											language="javascript"
											value={data[f.key]}
											style={{padding: "1em 0", background: "#1E1E1E"}}
											onChange={(ev, value) => this.setFieldValue(f.key, value)}
											theme={"vs-dark"}
											options={codeEditorOptions}
										/>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "json_code" ?
								<Col key={f.key} md={12} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<ControlledEditor
											height="600px"
											language="json"
											style={{padding: "1em 0", background: "#1E1E1E"}}
											value={data[f.key]}
											onChange={(ev, value) => this.setFieldValue(f.key, value)}
											theme={"vs-dark"}
											options={codeEditorOptions}
										/>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "html_code" ?
								<Col key={f.key} md={12} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<ControlledEditor
											height="600px"
											language="html"
											style={{padding: "1em 0", background: "#1E1E1E"}}
											value={data[f.key]}
											onChange={(ev, value) => this.setFieldValue(f.key, value)}
											theme={"vs-dark"}
											options={codeEditorOptions}
										/>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "css_code" ?
								<Col key={f.key} md={12} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<ControlledEditor
											height="600px"
											language="css"
											style={{padding: "1em 0", background: "#1E1E1E"}}
											value={data[f.key]}
											onChange={(ev, value) => this.setFieldValue(f.key, value)}
											theme={"vs-dark"}
											options={codeEditorOptions}
										/>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "topology" ?
								<Col md={6} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<Select value={data[f.key]} helperText={this.validateFieldValue(f.key, data[f.key])} disabled={this.isFieldDisabled(f)} onChange={(references) => this.setFieldValue(f.key, references.target.value)} fullWidth error={this.validateFieldValue(f.key, data[f.key])} labelId={"type-label"}>
											<MenuItem value={""}>Nessuno</MenuItem>
											{
												referenceableRecords[f.key] ?
													referenceableRecords[f.key].map(f => <MenuItem value={f.data.ref}>{f.data.name}</MenuItem>)
													:
													null
											}
										</Select>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "auto_topology" ?
								<Col md={6} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<Select value={data[f.key]} helperText={this.validateFieldValue(f.key, data[f.key])} disabled={this.isFieldDisabled(f)} onChange={(references) => this.setFieldValue(f.key, references.target.value)} fullWidth error={this.validateFieldValue(f.key, data[f.key])} labelId={"type-label"}>
											<MenuItem value={""}>Nessuno</MenuItem>
											{
												referenceableRecords[f.key] ?
													referenceableRecords[f.key].map(f => <MenuItem value={f._id}>{f.data.title}</MenuItem>)
													:
													null
											}
										</Select>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "auto_topology_list" ?
								<Col md={12} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										{referenceableRecords[f.key] ?
											referenceableRecords[f.key].map((refRecord, index) => (
												<FormControlLabel
													key={index}
													control={
														<Checkbox checked={data[f.key] ? data[f.key].includes(refRecord._id) : false} onChange={(v) => this.addItemToFieldValueList(f.key, refRecord._id, v.target.checked)} />
													}
													label={refRecord.data.label}
												/>
											)) : null
										}
										<br />
										<AddRecordDialog
											size={"lg"}
											topology={referenceableTopologies[f.key]}
											topologies={this.props.topologies}
											confirmCallback={() => this.loadData()}
											button={<Button>Aggiungi {referenceableTopologies[f.key].label}</Button>}
										/>
									</FormGroup>

								</Col>
								:
								null
						}

					</React.Fragment>


				))}
			</Row>
		);
	}

}
