import React from "react";
import {useDropzone} from "react-dropzone";
import {Card, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Button, InputLabel} from "@material-ui/core";

export default class Base64ImageDropDialog extends React.Component {

	state = {
		modal: false,
		data: {}
	};

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.confirm = this.confirm.bind(this);
		this.isDataValid = this.isDataValid.bind(this);
	}

	toggle() {
		this.setState(previousState => ({modal: !previousState.modal}) )
	}

	isDataValid() {
		return true;
	}

	confirm() {
		const { data } = this.state;
		if(this.isDataValid()) {
			this.props.confirmCallback({...data});
			this.toggle();
		}
	}

	render() {

		const { data } = this.state;
		const MyDropzone = () => {
			const [dropState, setDropState] = React.useState({ valid: false, used: false });
			const toBase64 = file => new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = error => reject(error);
			});
			const onDrop = React.useCallback(async acceptedFiles => {
				if(acceptedFiles.length === 1) {
					const b64 = await toBase64(acceptedFiles[0]);
					if(b64.split(";")[0] === "data:image/jpg" ||
						b64.split(";")[0] === "data:image/png" ||
						b64.split(";")[0] === "data:image/jpeg") {
						this.setState({ data: { base64: b64 } }, () => this.confirm());
					} else {
						console.log("Must be image.")
					}
				} else {
					console.log("Too many files");
				}
			}, [])
			const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

			return (
				<div {...getRootProps()}>
					<input {...getInputProps()} />
					{
						isDragActive ?
							(
								<>
									<Card className={"bg-secondary"} body>
										<h6>Trascina l'immagine qui, oppure fai click</h6>
									</Card>
									<InputLabel error={true}>File non valido. Inserisci un solo file di tipo immagine.</InputLabel>
								</>
							) :
							(
								<>
									<Card className={"bg-secondary"} body>
										<p>Trascina l'immagine qui, oppure fai click</p>
									</Card>
									{ (dropState.used && !dropState.valid) ? <InputLabel error={true}>File non valido. Inserisci un solo file di tipo immagine.</InputLabel> : null }
								</>
							)
					}
				</div>
			)
		}

		return (
			<React.Fragment>
				<div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
					<div onClick={this.toggle}> {this.props.button}</div>
					<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
						<ModalHeader toggle={this.toggle}>Carica un'immagine</ModalHeader>
						<ModalBody>
							<Card body className="card-shadow-primary border mb-3">
								<MyDropzone />
							</Card>
						</ModalBody>
						<ModalFooter>
							<Button outline color="danger" className={"border-0 btn-transition"} onClick={this.toggle}> Annulla </Button>
							<Button color="success" onClick={this.confirm}> Salva </Button>
						</ModalFooter>
					</Modal>
				</div>
			</React.Fragment>
		)
	}
}

