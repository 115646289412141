import React from 'react';
import {
	Button, Card, CardTitle, Col, CustomInput, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { InputLabel, TextField, Select, MenuItem } from '@material-ui/core';


class AddFieldDialog extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			key: "",
			label: "",
			type: "",
			required: false,
			dataType: ""
		};

		this.confirm = this.confirm.bind(this);
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	confirm() {
		const { key, label, type, dataType, required, references } = this.state;
		this.props.confirmCallback({ key, label, type, references, validations: { dataType, required } });
		this.toggle();
	}

	render() {

		const fieldTypes = [
			{name: "input", label: "Input"},
			{name: "textarea", label: "Area di testo"},
			{name: "select", label: "Select"},
			{name: "checkbox", label: "Checkbox"},
			{name: "wysiwyg", label: "WhatYouSeeIsWhatYouGet"},
			{name: "date", label: "Data"},
			{name: "hidden", label: "Nascosto"},
			{name: "image", label: "Immagine"},
			{name: "html_code", label: "Codice HTML"},
			{name: "css_code", label: "Codice CSS"},
			{name: "js_code", label: "Codice Javascript"},
			{name: "json_code", label: "Codice JSON"},
			{name: "location", label: "Coordinate Geospaziali"},
			{name: "topology", label: "Oggetto"},
			{name: "auto_topology", label: "Oggetto Autoreferenziato"},
			{name: "topology_list", label: "Lista Oggetti"},
			{name: "auto_topology_list", label: "Lista Oggetti Autoreferenziati"}
		];

		const fieldDataTypes = [
			{name: "any", label: "Qualsiasi"},
			{name: "alpha", label: "Alfabetico"},
			{name: "num", label: "Numerico"},
			{name: "alphanum", label: "Alfanumerico"},
			{name: "checkbox", label: "Valore Checkbox"},
			{name: "html", label: "HTML"},
			{name: "email", label: "Email"},
			{name: "date", label: "Data"},
			{name: "image", label: "Immagine"},
			{name: "object", label: "Oggetto" },
			{name: "object_list", label: "Lista Oggetti"},
			{name: "webcode", label: "HTML / CSS / JS / JSON"},
			{name: "coordinates", label: "Coordinate Geospaziali"},
			{name: "code_block", label: "Blocco"}
		];

		const { key, label, type, required, dataType, references } = this.state;
		const { topologies } = this.props;

		return (
			<React.Fragment>
				<span className="d-inline-block">
					<span onClick={this.toggle}> {this.props.button}</span>
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
            <ModalHeader toggle={this.toggle}>{ this.props.title }</ModalHeader>
	          <ModalBody>
		          <Card body className="card-shadow-primary border mb-3">
			          <CardTitle>Caratteristiche</CardTitle>
			          <Row form>
									<Col md={12}>
										<FormGroup>
											<InputLabel id="type-label">Tipo</InputLabel>
											<Select value={type} onChange={ (type) => this.setState({type: type.target.value}) } fullWidth labelId={"type-label"}>
												{ fieldTypes.map(f => <MenuItem value={f.name}>{f.label}</MenuItem>) }
											</Select>
										</FormGroup>
									</Col>
				          {
					          ["topology", "auto_topology", "auto_topology_list"].includes(type) ?
						          <Col md={12}>
							          <FormGroup>
								          <InputLabel id="type-label">Oggetto Referenziato</InputLabel>
								          <Select value={references} onChange={(references) => this.setState({references: references.target.value})} fullWidth labelId={"type-label"}>
									          {topologies.map(f => <MenuItem value={f._id}>{f.label}</MenuItem>)}
								          </Select>
							          </FormGroup>
						          </Col>
						          :
						          null
				          }
				          <Col md={12}>
										<FormGroup>
											<TextField name="key" value={key} onChange={(key) => this.setState({key: key.target.value})} type="text" label={"Chiave"} fullWidth />
										</FormGroup>
									</Col>
									<Col md={12}>
										<FormGroup>
											<TextField name="label" value={label} onChange={(label) => this.setState({label: label.target.value})} type="text" label={"Label"} fullWidth />
										</FormGroup>
									</Col>
		            </Row>
		          </Card>
		          <Card body className="card-shadow-primary border mb-3">
			          <Row form>
			            <CardTitle>Validazione dati</CardTitle>
				          <Col md={12}>
					          <FormGroup>
											<InputLabel id="type-label">Tipo</InputLabel>
											<Select value={dataType} onChange={(dataType) => this.setState({dataType: dataType.target.value})} fullWidth labelId={"type-label"}>
												{fieldDataTypes.map(f => <MenuItem value={f.name}>{f.label}</MenuItem>)}
											</Select>
										</FormGroup>
				          </Col>
									<Col md={12}>
				            <FormGroup>
					            <CustomInput value={required} onChange={(e) => this.setState({required: e.target.checked })} type="checkbox" id="checkboxRequired" label="Obbligatorio"/>
				            </FormGroup>
			            </Col>
		            </Row>
		          </Card>
	          </ModalBody>
            <ModalFooter>
              <Button outline color="danger" className={"border-0 btn-transition"} onClick={this.toggle}> Annulla </Button>
              <Button color="success" onClick={this.confirm}> Aggiungi </Button>
            </ModalFooter>
          </Modal>
				</span>
			</React.Fragment>
		);
	}
}

export default AddFieldDialog;
