import React, {Component} from "react";
import Validator from "../../helpers/Validations";
import {Card, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import RecordDataForm from "./RecordDataForm";
import {Button} from "@material-ui/core";
import ApiService from "../../services/classes/Api";
import {Slide, toast} from "react-toastify";

export default class AddRecordDialog extends Component {

	state = {
		modal: false,
		data: {}
	};

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.confirm = this.confirm.bind(this);
		this.isDataValid = this.isDataValid.bind(this);
	}

	toggle() {
		this.setState(previousState => ({modal: !previousState.modal}) )
	}

	isDataValid() {
		const state = "new";
		const { data } = this.state;
		const { topology } = this.props;
		const result = Validator.validate(data, topology, state);
		console.log(result);
		return result.length <= 0;
	}

	confirm() {
		const { data } = this.state;
		const { topology } = this.props;
		if(this.isDataValid()) {
			ApiService.createRecord(topology.name, data, "new").then(response => {
				if(response.status === 200) {
					toast(`${topology.label} creato/a correttamente.`, {
						transition: Slide,
						closeButton: true,
						autoClose: 2000,
						position: 'bottom-center',
						type: 'success'
					});
					this.props.confirmCallback(true);
					this.toggle();
				}
			}).catch(e => {
				console.log(e)
				toast("Si è verificato un problema, riprova più tardi.", {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'error'
				});
			})
		}
	}

	render() {

		const { name, label, plural_label } = this.props.topology;

		return (
			<React.Fragment>
				<span className="d-inline-block">
					<span onClick={this.toggle}> {this.props.button}</span>
						<Modal size={this.props.size || ""} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
      	    	<ModalHeader toggle={this.toggle}>Crea nuova { label }</ModalHeader>
	    	    	<ModalBody>
								<Card body className="card-shadow-primary border mb-3">
                  <RecordDataForm state={"new"} updateRecordData={(data) => this.setState({data})} topology={this.props.topology} data={{}} topologies={this.props.topologies} />
		  	    	  </Card>
	    	    	</ModalBody>
      	    	<ModalFooter>
      	    	  <Button outline color="danger" className={"border-0 btn-transition"} onClick={this.toggle}> Annulla </Button>
      	    	  <Button color="success" onClick={this.confirm}> Salva </Button>
      	    	</ModalFooter>
      	   </Modal>
				</span>
			</React.Fragment>
		)
	}
};